@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&display=swap');

*,
*::after,
*::before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

:root {
    --color-1: #1b1b1b;
    --color-2: #fff;
    --color-3: #535353;
    --color-4: #c9c9c9;
    --color-5: #ce0000;
}

html {
  scroll-behavior: smooth;
}

body {
    font-family: 'Inter', sans-serif;
    color: var(--color-1);
}

h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
}

h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
}

h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.3;
}

h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
}

h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
}

.cta-btn {
  border: 2px solid var(--color-2);
  padding: 12px 17px;
  text-align: center;
  cursor: pointer;
}

.btn-style-1 {
  border: 2px solid var(--color-1);
}

.btn-style-2 {
  background-color: var(--color-1);
  color: var(--color-2);
}

input {
  border: none;
  background: none;
  outline: none;
  font: inherit;
}

/* Helpers */
.img-res {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon {
  color: var(--color-1);
  stroke-width: 1.5;
  cursor: pointer;
}

.mt-1 {
  margin-top: 20px;
}

.mt-2 {
  margin-top: 40px;
}

.mt-3 {
  margin-top: 80px;
}

/* Mobile */
@media (max-width: 992px) {
  h1 {
    font-size: 44px;
  }
  
  h2 {
    font-size: 32px;
  }
  
  h3 {
    font-size: 26px;
  }
  
  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }
}