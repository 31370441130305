.resert-pwd {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px;
}

.resert-pwd h2 {
  margin-bottom: 40px;
}

.resert-pwd .pwd-error-message {
  width: 100%;
  margin-top: 0;
}