.order-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 60px;
  text-align: center;
}

.order-confirmation-icon {
  width: 120px;
  height: 120px;
}

.order-confirmation-div-line {
  width: 60px;
  height: 1px;
  background-color: var(--color-3);
} 

/* Mobile */
@media (max-width: 768px) {
  .order-confirmation {
    padding: 80px 30px;
  }
}