.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0 60px;
  color: var(--color-2);
}

.hero h1, h4 {
  text-align: center;
  line-height: 1.3;
}

.hero::after {
  content: '';
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.hero-bg {
  position: absolute;
  top: 0; left: 0;
  z-index: -2;
}

.products-container {
  padding: 80px 40px;
}

.products-container h3 {
  padding: 0 20px;
}

.products {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 0;
}

/* Mobile */
@media (max-width: 992px) {
  .hero {
    height: 50vh;
  }

  .products {
    gap: 60px 0;
  }
}