.about-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 140px 0;
  background-color: var(--color-1);
}

.about-hero-bg {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
}

.about-hero-bg::after {
  content: '';
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
}

.About h1 {
  color: var(--color-2);
  z-index: 1;
}

.About h3 {
  font-weight: 700;
}

.About section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.about-intro {
  padding: 80px 25%;
}

.about-div-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  height: 1px;
  background-color: var(--color-3);
}

.about-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 40px;
  width: 100%;
  padding: 80px 25%;
}

.about-section-textarea {
  width: 50%;
  text-align: left;
}

.about-section-icon {
  width: 120px;
  height: 120px;
}

/* Mobile */
@media (max-width: 992px) {
  .about-intro {
    padding: 80px 60px;
  }

  .about-section {
    padding: 80px 20%;
  }

  .about-section-icon {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 768px) {
  .about-intro,
  .about-section {
    padding: 60px;
  }

  .about-div-line {
    width: 90%;
  }

  .about-section-textarea {
    width: 100%;
  }

  .about-section-icon {
    display: none;
  }
}