.Header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 60px;
  border-bottom: 1px solid var(--color-3);
}

.header-menu ul,
.header-icons {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 340px;
}

.header-menu li {
  cursor: pointer;
}

.header-logo {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.header-icon {
  position: relative;
  width: 25px;
  height: 25px;
}

.cart-prod-add {
  position: absolute;
  top: 1px;
  right: -4px;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--color-1);
}

/* Mobile */
.header-menu-icon {
  display: none;
}

.header-menu-m {
  display: none;
}

@media (max-width: 992px) {
  .Header {
    padding: 15px 30px;
  }

  .header-menu {
    display: none;
  }

  .header-menu-icon {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 300px;
  }

  .header-menu-m {
    position: fixed;
    top: 0; left: 0;
    display: block;
    width: 100%;
    height: 100vh;
    padding: 60px;
    background-color: rgba(27, 27, 27, .9);
    color: var(--color-2);
    font-size: 20px;
    z-index: 998;
    transition: all .5s ease;
  }

  .header-menu-m ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .header-menu-m li:nth-child(1) {
    display: flex;
    justify-content: right;
  }

  .header-menu-hidden {
    left: -100%;
  }
}